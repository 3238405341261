import "./home-page.css";
import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SearchResults from "../../components/search-results/SearchResults";
import { useSelector } from "react-redux";
import ViewBlock from "../../components/view-block/ViewBlock";

const HomePage = () => {
  const filters = useSelector(function (state) {
    return state.currentFilters.data;
  });

  return (
    <>
      <Header />
      {filters ? (
        <SearchResults filters={filters}/>
      ) : (
        <>
          <h2 className="home-header">Վաճառքի բնակարաններ</h2>
          <ViewBlock tp={"sell"} itm={"Բնակարան"}/>
          <h2 className="home-header">Վաճառքի առանձնատներ</h2>
          <ViewBlock tp={"sell"} itm={"Առանձնատուն"}/>
          <h2 className="home-header">Վարձով բնակարաններ</h2>
          <ViewBlock tp={"rent"} itm={"Բնակարան"}/>
          <h2 className="home-header">Վարձով առանձնատներ</h2>
          <ViewBlock tp={"rent"} itm={"Առանձնատուն"}/>
        </>
      )}
      <Footer />
    </>
  );
};

export default HomePage;
